import * as React from "react"
import { Link } from "gatsby"
import SearchEngineOptimisation from "../components/SearchEngineOptimisation"
import Hero from "../components/Hero"
import Footer from "../components/Footer"
import '../styles/styles.scss'

const TermsPage = () => {
    return (
        <>
            <main>
                <SearchEngineOptimisation title="Terms & Conditions" keywords={"terms conditions"} path="/terms" index={false} />

                <Hero />
                <div className="legal container">
                    <Link to='/'>{'<'} Back to app</Link>
                    <h1>Terms and Conditions:</h1>
                    <p>This app does not allow user signup or sign in and we do not hold any type of personal data or data that may be personally identifiable (except for emails which we have recieved with consent).</p>
                    <p>We do not hold any personalbly identifiable user data without the users consent.</p>
                    <p>With you consent you can submit your email to be notified on the release of new apps and software. By submitting your email you consent to us holding your data.</p>
                    <p>When you submit your email your email will be stored in MailChimp. You can request to have your data deleted by messaging me, or a simplier way would be to unsubsribe from the emails you have received from us.</p>
                    <p>You are free to share content from this website as long as credit is given to us (sharing urls and articles is all fine). You are not allowed to take our work and rebrand it as your own under copyright law.</p>
                    <p>We welcome anyone wanting to share our work within publications, articles, videos, or social media.</p>
                    <p>The apps and websites we have developed have their own terms of service, terms and conditions and privacy policies. This terms and conditions is for https://human.games only and it's duplicate domains. If you are using one of our apps please view the relevent legal material in the footer of that app.</p>
                    <p>We do not allow users to submit other peoples email within our form. Understand that we do not take any responsibility for users looking to submit other peoples emails. If we find a user has been submitting emails that do not belong to them we will delete the emails that have been submitted.</p>
                </div>
            </main>
            <Footer />
        </>
    )
}

export default TermsPage